<template>
  <b-input-group>
    <b-form-input v-model="newValue" :disabled="!editMode"></b-form-input>
    <template #append style="padding: 0">
      <template v-if="editMode">
        <b-button :disabled="isUpdating" size="small" @click="doUpdate">
          <i
            :class="[isUpdating ? doUpdateRunningClass : doUpdateInitialClass]"
          ></i>
        </b-button>
        <b-button :disabled="isUpdating" size="small" @click="cancelUpdate">
          <i class="fas fa-window-close text-danger"></i>
        </b-button>
      </template>
      <template v-else>
        <b-button @click="enterUpdateMode">
          <i class="fas fa-pencil-alt text-primary"></i>
        </b-button>
      </template>
    </template>
  </b-input-group>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
export default {
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    currentValue: String,
    type: {
      type: String,
      validator: (value) =>
        ['fullName', 'address', 'indentifyNo'].includes(value),
    },
  },
  data() {
    return {
      newValue: this.currentValue,
      editMode: false,
      isUpdating: false,
      doUpdateInitialClass: 'far fa-check-circle text-primary',
      doUpdateRunningClass: 'spinner spinner-primary',
    };
  },
  methods: {
    enterUpdateMode() {
      this.editMode = !this.editMode;
    },
    cancelUpdate() {
      this.editMode = false;
      this.newIdentifyNo = this.identifyNo;
    },
    doUpdate() {
      this.isUpdating = true;
      const payload = {};

      if (this.type === 'indentifyNo') {
        payload.indentifyNo = this.newValue;
      }

      ApiService.put('/customer/' + this.customerId, payload)
        .then((response) => {
          if (response.data.status === 1) {
            makeToastSuccess('Cập nhật thành công');
            this.editMode = false;
          } else {
            makeToastFaile(response.data.message);
          }
          this.isUpdating = false;
        })
        .catch(({ response }) => {
          if (response.data) {
            makeToastFaile(response.data.message);
          } else {
            makeToastFaile('Đã có lỗi xảy ra!');
          }
          this.isUpdating = false;
        });
    },
  },
};
</script>

<style scoped>
.info-import-trade-in {
  padding: 0px;
}
</style>
